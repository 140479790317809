import React from "react"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import Layout from "../components/Layout"
import Listing from "../components/Listing"
import Seo from "../components/Seo"

export default function Issue({ data }) {
  //Data destructuring
  const articlesData = data.articles.edges.map(edge => edge.node)
  const issueData = data.issue
  const seo = data.seo

  //Define & Assign
  const issueN = issueData.number ? issueData.number : ""
  const titleRu =
    issueData.title && issueData.title.ru ? issueData.title.ru : ""
  const titleFi =
    issueData.title && issueData.title.fi ? issueData.title.fi : ""
  const descRu =
    issueData.description && issueData.description.ru
      ? issueData.description.ru
      : ""
  const descFi =
    issueData.description && issueData.description.fi
      ? issueData.description.fi
      : ""
  const issueLink = issueData.link ? issueData.link : ""
  const alt = `${titleRu} / ${titleFi}`
  const imageWidthV = 640
  const imageWidthH = 1500

  let assetH = null
  let assetV = null
  if (issueData.covers.length > 0) {
    for (const cover of issueData.covers) {
      let setV = false
      let setH = false
      if (cover.format === "square-animated") {
        assetV = cover.image && cover.image.asset
        setV = true
      } else if (!setV && cover.format === "square") {
        assetV = cover.image && cover.image.asset
      } else if (cover.format === "landscape-animated") {
        assetH = cover.image && cover.image.asset
        setH = true
      } else if (!setH && cover.format === "landscape") {
        assetH = cover.image && cover.image.asset
      }
    }
  }

  return (
    <Layout>
      <Seo seoData={seo} />
      <section className="issue-header">
        {assetV && (
          <SanityImage
            asset={assetV}
            alt={alt}
            width={imageWidthV}
            className="issue-cover vertical"
          />
        )}
        {assetH && (
          <SanityImage
            asset={assetH}
            alt={alt}
            width={imageWidthH}
            className="issue-cover horizontal"
          />
        )}
        <div className="issue-info">
          <span className="issue-number name-caption">{`№${issueN}`}</span>
          <h1 className="issue-title">
            <span className="russian" lang="ru">
              {titleRu}
            </span>
            <span className="finnish" lang="fi">
              {titleFi}
            </span>
          </h1>
          {descRu && descFi && (
            <p className="issue-desc">
              <span className="russian" lang="ru">
                {descRu}
              </span>
              <span className="finnish" lang="fi">
                {descFi}
              </span>
            </p>
          )}
          {issueLink && (
            <a
              href={issueLink}
              target="_blank"
              rel="noreferrer"
              className="issue-link"
            >
              Читать / Lue
            </a>
          )}
        </div>
      </section>
      <section className="issue-body">
        <Listing data={articlesData} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetIssue($id: String) {
    issue: sanityIssue(_id: { eq: $id }) {
      _id
      _key
      number
      title {
        fi
        ru
      }
      covers {
        format
        image {
          asset {
            _id
          }
        }
      }
      description {
        fi
        ru
      }
      link
    }
    articles: allSanityArticle(
      filter: { issue: { _id: { eq: $id } } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          _id
          _type
          date
          slug {
            current
          }
          cover {
            asset {
              _id
            }
          }
          thumb {
            asset {
              _id
            }
          }
          title {
            fi
            ru
          }
          description {
            fi
            ru
          }
          section {
            title {
              ru
              fi
            }
            slug {
              current
            }
          }
          author {
            name {
              ru
              fi
            }
          }
        }
      }
    }
    seo: sanityIssue(_id: { eq: $id }) {
      _id
      _type
      number
      title {
        fi
        ru
      }
      slug {
        current
      }
      covers {
        format
        image {
          asset {
            _id
          }
        }
      }
      description {
        fi
        ru
      }
      seo {
        metaTitle
        metaDescription
        metaKeywords
        sharedImage {
          asset {
            _id
          }
        }
        preventIndexing
      }
    }
  }
`
